exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-categories-index-js": () => import("./../../../src/pages/categories/index.js" /* webpackChunkName: "component---src-pages-categories-index-js" */),
  "component---src-pages-contact-thanks-js": () => import("./../../../src/pages/contact/thanks.js" /* webpackChunkName: "component---src-pages-contact-thanks-js" */),
  "component---src-pages-tags-index-js": () => import("./../../../src/pages/tags/index.js" /* webpackChunkName: "component---src-pages-tags-index-js" */),
  "component---src-templates-about-me-page-js": () => import("./../../../src/templates/about-me-page.js" /* webpackChunkName: "component---src-templates-about-me-page-js" */),
  "component---src-templates-about-us-page-js": () => import("./../../../src/templates/about-us-page.js" /* webpackChunkName: "component---src-templates-about-us-page-js" */),
  "component---src-templates-application-security-services-js": () => import("./../../../src/templates/application-security-services.js" /* webpackChunkName: "component---src-templates-application-security-services-js" */),
  "component---src-templates-application-security-testing-js": () => import("./../../../src/templates/application-security-testing.js" /* webpackChunkName: "component---src-templates-application-security-testing-js" */),
  "component---src-templates-archives-js": () => import("./../../../src/templates/archives.js" /* webpackChunkName: "component---src-templates-archives-js" */),
  "component---src-templates-blog-page-js": () => import("./../../../src/templates/blog-page.js" /* webpackChunkName: "component---src-templates-blog-page-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-categories-js": () => import("./../../../src/templates/categories.js" /* webpackChunkName: "component---src-templates-categories-js" */),
  "component---src-templates-contact-page-js": () => import("./../../../src/templates/contact-page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-content-page-js": () => import("./../../../src/templates/content-page.js" /* webpackChunkName: "component---src-templates-content-page-js" */),
  "component---src-templates-data-protection-js": () => import("./../../../src/templates/data-protection.js" /* webpackChunkName: "component---src-templates-data-protection-js" */),
  "component---src-templates-dynamic-penetration-testing-js": () => import("./../../../src/templates/dynamic-penetration-testing.js" /* webpackChunkName: "component---src-templates-dynamic-penetration-testing-js" */),
  "component---src-templates-easy-lp-js": () => import("./../../../src/templates/easy-lp.js" /* webpackChunkName: "component---src-templates-easy-lp-js" */),
  "component---src-templates-embedded-security-js": () => import("./../../../src/templates/embedded-security.js" /* webpackChunkName: "component---src-templates-embedded-security-js" */),
  "component---src-templates-hybrid-security-analysis-js": () => import("./../../../src/templates/hybrid-security-analysis.js" /* webpackChunkName: "component---src-templates-hybrid-security-analysis-js" */),
  "component---src-templates-implications-consequences-data-js": () => import("./../../../src/templates/implications-consequences-data.js" /* webpackChunkName: "component---src-templates-implications-consequences-data-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-landing-page-js": () => import("./../../../src/templates/landing-page.js" /* webpackChunkName: "component---src-templates-landing-page-js" */),
  "component---src-templates-mobile-application-js": () => import("./../../../src/templates/mobile-application.js" /* webpackChunkName: "component---src-templates-mobile-application-js" */),
  "component---src-templates-network-penetration-testing-js": () => import("./../../../src/templates/network-penetration-testing.js" /* webpackChunkName: "component---src-templates-network-penetration-testing-js" */),
  "component---src-templates-network-security-testing-js": () => import("./../../../src/templates/network-security-testing.js" /* webpackChunkName: "component---src-templates-network-security-testing-js" */),
  "component---src-templates-network-vulnerability-scanning-js": () => import("./../../../src/templates/network-vulnerability-scanning.js" /* webpackChunkName: "component---src-templates-network-vulnerability-scanning-js" */),
  "component---src-templates-ppc-main-lp-page-js": () => import("./../../../src/templates/ppc-main-lp-page.js" /* webpackChunkName: "component---src-templates-ppc-main-lp-page-js" */),
  "component---src-templates-ppc-main-lp-thanks-page-js": () => import("./../../../src/templates/ppc-main-lp-thanks-page.js" /* webpackChunkName: "component---src-templates-ppc-main-lp-thanks-page-js" */),
  "component---src-templates-resources-page-js": () => import("./../../../src/templates/resources-page.js" /* webpackChunkName: "component---src-templates-resources-page-js" */),
  "component---src-templates-resources-post-js": () => import("./../../../src/templates/resources-post.js" /* webpackChunkName: "component---src-templates-resources-post-js" */),
  "component---src-templates-resources-post-thanks-js": () => import("./../../../src/templates/resources-post-thanks.js" /* webpackChunkName: "component---src-templates-resources-post-thanks-js" */),
  "component---src-templates-security-assessments-js": () => import("./../../../src/templates/security-assessments.js" /* webpackChunkName: "component---src-templates-security-assessments-js" */),
  "component---src-templates-sitemap-js": () => import("./../../../src/templates/sitemap.js" /* webpackChunkName: "component---src-templates-sitemap-js" */),
  "component---src-templates-ssdlc-development-phase-js": () => import("./../../../src/templates/ssdlc-development-phase.js" /* webpackChunkName: "component---src-templates-ssdlc-development-phase-js" */),
  "component---src-templates-ssdlc-introduction-js": () => import("./../../../src/templates/ssdlc-introduction.js" /* webpackChunkName: "component---src-templates-ssdlc-introduction-js" */),
  "component---src-templates-ssdlc-page-js": () => import("./../../../src/templates/ssdlc-page.js" /* webpackChunkName: "component---src-templates-ssdlc-page-js" */),
  "component---src-templates-ssdlc-planning-design-phase-js": () => import("./../../../src/templates/ssdlc-planning-design-phase.js" /* webpackChunkName: "component---src-templates-ssdlc-planning-design-phase-js" */),
  "component---src-templates-ssdlc-release-phase-js": () => import("./../../../src/templates/ssdlc-release-phase.js" /* webpackChunkName: "component---src-templates-ssdlc-release-phase-js" */),
  "component---src-templates-ssdlc-requirements-phase-js": () => import("./../../../src/templates/ssdlc-requirements-phase.js" /* webpackChunkName: "component---src-templates-ssdlc-requirements-phase-js" */),
  "component---src-templates-ssdlc-security-training-phase-js": () => import("./../../../src/templates/ssdlc-security-training-phase.js" /* webpackChunkName: "component---src-templates-ssdlc-security-training-phase-js" */),
  "component---src-templates-ssdlc-verification-testing-phase-js": () => import("./../../../src/templates/ssdlc-verification-testing-phase.js" /* webpackChunkName: "component---src-templates-ssdlc-verification-testing-phase-js" */),
  "component---src-templates-static-analysis-js": () => import("./../../../src/templates/static-analysis.js" /* webpackChunkName: "component---src-templates-static-analysis-js" */),
  "component---src-templates-terms-and-conditions-for-giveaway-js": () => import("./../../../src/templates/terms-and-conditions-for-giveaway.js" /* webpackChunkName: "component---src-templates-terms-and-conditions-for-giveaway-js" */),
  "component---src-templates-thanks-page-js": () => import("./../../../src/templates/thanks-page.js" /* webpackChunkName: "component---src-templates-thanks-page-js" */),
  "component---src-templates-video-page-js": () => import("./../../../src/templates/video-page.js" /* webpackChunkName: "component---src-templates-video-page-js" */),
  "component---src-templates-web-application-js": () => import("./../../../src/templates/web-application.js" /* webpackChunkName: "component---src-templates-web-application-js" */),
  "component---src-templates-web-dynamic-penetration-testing-js": () => import("./../../../src/templates/web-dynamic-penetration-testing.js" /* webpackChunkName: "component---src-templates-web-dynamic-penetration-testing-js" */),
  "component---src-templates-web-hybrid-analysis-js": () => import("./../../../src/templates/web-hybrid-analysis.js" /* webpackChunkName: "component---src-templates-web-hybrid-analysis-js" */),
  "component---src-templates-web-static-analysis-js": () => import("./../../../src/templates/web-static-analysis.js" /* webpackChunkName: "component---src-templates-web-static-analysis-js" */),
  "component---src-templates-why-security-testing-js": () => import("./../../../src/templates/why-security-testing.js" /* webpackChunkName: "component---src-templates-why-security-testing-js" */)
}

